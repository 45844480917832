{
  "_args": [
    [
      "openai@3.2.1",
      "/opt/render/project/src"
    ]
  ],
  "_from": "openai@3.2.1",
  "_id": "openai@3.2.1",
  "_inBundle": false,
  "_integrity": "sha512-762C9BNlJPbjjlWZi4WYK9iM2tAVAv0uUp1UmI34vb0CN5T2mjB/qM6RYBmNKMh/dN9fC+bxqPwWJZUTWW052A==",
  "_location": "/openai",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "openai@3.2.1",
    "name": "openai",
    "escapedName": "openai",
    "rawSpec": "3.2.1",
    "saveSpec": null,
    "fetchSpec": "3.2.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/openai/-/openai-3.2.1.tgz",
  "_spec": "3.2.1",
  "_where": "/opt/render/project/src",
  "author": {
    "name": "OpenAI"
  },
  "bugs": {
    "url": "https://github.com/openai/openai-node/issues"
  },
  "dependencies": {
    "axios": "^0.26.0",
    "form-data": "^4.0.0"
  },
  "description": "Node.js library for the OpenAI API",
  "devDependencies": {
    "@types/node": "^12.11.5",
    "typescript": "^3.6.4"
  },
  "homepage": "https://github.com/openai/openai-node#readme",
  "keywords": [
    "openai",
    "open",
    "ai",
    "gpt-3",
    "gpt3"
  ],
  "license": "MIT",
  "main": "./dist/index.js",
  "name": "openai",
  "repository": {
    "type": "git",
    "url": "git+ssh://git@github.com/openai/openai-node.git"
  },
  "scripts": {
    "build": "tsc --outDir dist/"
  },
  "types": "./dist/index.d.ts",
  "version": "3.2.1"
}
