"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertClerkLoadedGuarantee = exports.assertWrappedByClerkProvider = void 0;
const errors_1 = require("../errors");
function assertWrappedByClerkProvider(contextVal) {
    if (!contextVal) {
        throw new Error(errors_1.noClerkProviderError);
    }
}
exports.assertWrappedByClerkProvider = assertWrappedByClerkProvider;
function assertClerkLoadedGuarantee(guarantee, hookName) {
    if (!guarantee) {
        throw new Error((0, errors_1.noGuaranteedLoadedError)(hookName));
    }
}
exports.assertClerkLoadedGuarantee = assertClerkLoadedGuarantee;
