"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClerk = void 0;
const IsomorphicClerkContext_1 = require("../contexts/IsomorphicClerkContext");
const useClerk = () => {
    const isomorphicClerk = (0, IsomorphicClerkContext_1.useIsomorphicClerkContext)();
    // The actual value is an instance of IsomorphicClerk, not Clerk
    // we expose is as a Clerk instance
    return isomorphicClerk;
};
exports.useClerk = useClerk;
