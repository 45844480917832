import { useQuery } from "react-query"
import axios from "axios";


export const loadChats = () => {
  const results = axios.get("https://pokeapi.co/api/v2/pokemon/").then((res) => res.data.results);
  return results;
}
export default function useChatsQuery() {
  return useQuery({
    queryKey: ['chats'],
    queryFn: loadChats,
  })
};




