import { Badge, Button, Container, Group, SimpleGrid, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { IconCloudDownload, IconNumber1, IconNumber2, IconNumber3 } from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";

import axios from "axios";
import { nanoid } from "nanoid";
import { useCookies } from "react-cookie";

import { db } from "../db";

import { styleSettings } from "../utils/styleConstants";

export function IndexRoute() {
  const theme = useMantineTheme();
  const [cookies] = useCookies();

  const getStartedSteps = [
    {
      icon: IconNumber1,
      title: "Click \"New Chat\"",
      description:
        "It's a big orange button.<br><br>Clicking it will get you access to our \"experts and needs\" section." ,
    },
    {
      icon: IconNumber2,
      title: "Choose an expert",
      description:
        "Or a \"need\". Experts are great if you have a question that relates to their area of expertise. Needs are specific and deliver exactly what you \"need\".",
    },
    {
      icon: IconNumber3,
      title: "Start typing",
      description:
        "Our expert tuned AI will guide you with questions and answers to 10x your abilities, dramatically upgrading the AI response.",
    },
  ];

  return (
    <>
      <Container
        py={styleSettings.indexRoute.outerContainer.paddingY}
        sx={{
          height: styleSettings.indexRoute.outerContainer.sxProp.height
        }}
        className={styleSettings.indexRoute.outerContainer.className}
      >
        <Container
          size={styleSettings.indexRoute.innerContainer.size}
        >
          <Badge
            size={styleSettings.indexRoute.badge.size}
            mb={styleSettings.indexRoute.badge.marginBottom}
          >
            How to get started
          </Badge>
          <Text
            mt={styleSettings.indexRoute.header.marginTop}
            size={styleSettings.indexRoute.header.size}
            sx={{
              color: styleSettings[theme.colorScheme].textColor,
            }}
          >
            Get expert assistance using AI in 3 steps
          </Text>
          <SimpleGrid
            mt={styleSettings.indexRoute.header.marginTop}
            cols={styleSettings.indexRoute.header.marginTop}
            spacing={styleSettings.indexRoute.header.marginTop}
            breakpoints={[{
              maxWidth: styleSettings.indexRoute.getStartedGrid.breakpoints.maxWidth,
              cols: styleSettings.indexRoute.getStartedGrid.breakpoints.cols
            }]}
          >
            {getStartedSteps.map((step) => (
              <div key={step.title}>
                <ThemeIcon
                  color={styleSettings.indexRoute.getStartedStep.outerCircle.color[theme.colorScheme]}
                  radius={styleSettings.indexRoute.getStartedStep.outerCircle.radius}
                  size={styleSettings.indexRoute.getStartedStep.outerCircle.size}
                  variant={styleSettings.indexRoute.getStartedStep.outerCircle.variant}
                >
                  <ThemeIcon
                    color={styleSettings.indexRoute.getStartedStep.innerCircle.color[theme.colorScheme]}
                    radius={styleSettings.indexRoute.getStartedStep.innerCircle.radius}
                    size={styleSettings.indexRoute.getStartedStep.innerCircle.size}
                    variant={styleSettings.indexRoute.getStartedStep.innerCircle.variant}
                  >
                    <step.icon
                      color={styleSettings[theme.colorScheme].textColor}
                      size={styleSettings.indexRoute.getStartedStep.icon.size}
                      stroke={styleSettings.indexRoute.getStartedStep.icon.stroke}
                    />
                  </ThemeIcon>
                </ThemeIcon>
                <Text
                  mt={styleSettings.indexRoute.getStartedStep.step.title.marginTop}
                  mb={styleSettings.indexRoute.getStartedStep.step.title.marginBottom}
                  sx={{
                    color: styleSettings[theme.colorScheme].textColor,
                  }}
                >
                  {step.title}
                </Text>
                <Text
                  size={styleSettings.indexRoute.getStartedStep.step.description.size}
                  sx={{
                    color: styleSettings[theme.colorScheme].textColor,
                    lineHeight: styleSettings.indexRoute.getStartedStep.step.description.sxProp.lineHeight,
                  }}
                >
                  <div dangerouslySetInnerHTML={
                    {
                      __html: step.description
                    }
                  }></div>
                </Text>
              </div>
            ))}
          </SimpleGrid>
          <Group
            mt={styleSettings.indexRoute.downloadExtention.group.marginTop}
          >
            <Button
              // @ts-ignore
              component={styleSettings.indexRoute.downloadExtention.button.componentType}
              href="https://getexpertGPT.com/"
              size={styleSettings.indexRoute.downloadExtention.button.size}
              variant={styleSettings.indexRoute.downloadExtention.button.variant}
              leftIcon={<IconCloudDownload size={styleSettings.indexRoute.downloadExtention.button.leftIconSize} />}
            >
              Download Browser Extension
            </Button>
            <Text
              size={styleSettings.indexRoute.downloadExtention.button.text.size}
              sx={{
                color: styleSettings[theme.colorScheme].textColor,
              }}
            >
              Get the browser extension and bring expertGPT&trade; with you.
            </Text>
          </Group>
        </Container>
      </Container>
    </>
  );
}





// <Button
//   size="md"
//   sx={{
//     marginTop: "2rem",
//   }}
//   onClick={async (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     try {
//       const id = nanoid();
// // const authorization = document.cookie
// //   .split(';')
// //   .find((cookie) => cookie.trim().startsWith('__session='))
// //   .split('=')[1]
//
//
//       // Access to XMLHttpRequest at 'https://services.getexpertgpt.com/protected?id=i3hN302TiVDfLteF_rmsb' from origin 'https://appstaging.getexpertgpt.com' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource.
//
//       const authorization = cookies["__session"];
//       const response = await axios.get("https://services.getexpertgpt.com/protected?id=" + id, {
//         headers: {
//           authorization: authorization,
//         },
//       });
//       // Handle success state
//       console.log('Response:', response.data);
//     } catch (error) {
//       // Handle failure state
//       console.error('Error:', error);
//     }
//   } }
//   variant="outline"
//   leftIcon={<IconCloudDownload size={20} />}
// >
//   Test Authentication with services1
// </Button>
