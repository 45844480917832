import useChatsQuery from '../hooks/useChatsQuery'
export function Variables() {
  const { data: chats, status: postStatus, error: postError } = useChatsQuery()

  return (
    <div>
      {postStatus}<br />
      chats: {chats && chats.length + ':' + chats[0].name}
    </div>
  )
}
//
// export function MainLink({ icon, color, label, chatId }: MainLinkProps) {
//   const firstMessage = useLiveQuery(async () => {
//     return (await db.messages.orderBy("createdAt").toArray()).filter(
//       (m) => m.chatId === chatId
//     )[0];
//   }, [chatId]);
//
//   return (
//     <UnstyledButton
//       sx={(theme) => ({
//         // display: "block",
//         width: "100%",
//         padding: theme.spacing.xs,
//         borderRadius: theme.radius.sm,
//         color:
//           theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
//       })}
//     >
//       <Group>
//         <ThemeIcon color={color} variant="light">
//           {icon}
//         </ThemeIcon>
//         <Text
//           size="sm"
//           style={{
//             textOverflow: "ellipsis",
//             whiteSpace: "nowrap",
//             overflow: "hidden",
//             flex: 1,
//             width: 0,
//           }}
//         >
//           {label} <br />
//           {firstMessage?.content}
//         </Text>
//       </Group>
//     </UnstyledButton>
//   );
// }
