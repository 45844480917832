"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUser = void 0;
const UserContext_1 = require("../contexts/UserContext");
/**
 * Returns the current auth state and if a user is signed in, the user object.
 *
 * Until Clerk loads and initializes, `isLoaded` will be set to `false`.
 * Once Clerk loads, `isLoaded` will be set to `true`, and you can
 * safely access `isSignedIn` state and `user`.
 *
 * For projects using NextJs or Remix, you can make this state available during SSR
 * simply by using the `withServerSideAuth` helper and setting the `loadUser` flag to `true`.
 *
 *
 * @example
 * A simple example:
 *
 * import { useUser } from '@clerk/clerk-react'
 *
 * function Hello() {
 *   const { isSignedIn, user } = useUser();
 *   if(!isSignedIn) {
 *     return null;
 *   }
 *   return <div>Hello, {user.firstName}</div>
 * }
 *
 * @example
 * Basic example in a NextJs app. This page will be fully rendered during SSR:
 *
 * import { useUser } from '@clerk/nextjs'
 * import { withServerSideAuth } from '@clerk/nextjs/api'
 *
 * export getServerSideProps = withServerSideAuth({ loadUser: true});
 *
 * export HelloPage = () => {
 *   const { isSignedIn, user } = useUser();
 *   if(!isSignedIn) {
 *     return null;
 *   }
 *   return <div>Hello, {user.firstName}</div>
 * }
 *
 */
function useUser() {
    const user = (0, UserContext_1.useUserContext)();
    if (user === undefined) {
        return { isLoaded: false, isSignedIn: undefined, user: undefined };
    }
    if (user === null) {
        return { isLoaded: true, isSignedIn: false, user: null };
    }
    return { isLoaded: true, isSignedIn: true, user };
}
exports.useUser = useUser;
