"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSignUp = void 0;
const ClientContext_1 = require("../contexts/ClientContext");
const IsomorphicClerkContext_1 = require("../contexts/IsomorphicClerkContext");
const useSignUp = () => {
    const isomorphicClerk = (0, IsomorphicClerkContext_1.useIsomorphicClerkContext)();
    const client = (0, ClientContext_1.useClientContext)();
    if (!client) {
        return { isLoaded: false, signUp: undefined, setSession: undefined, setActive: undefined };
    }
    return {
        isLoaded: true,
        signUp: client.signUp,
        setSession: isomorphicClerk.setSession,
        setActive: isomorphicClerk.setActive,
    };
};
exports.useSignUp = useSignUp;
