"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.__internal__setErrorThrowerOptions = exports.errorThrower = void 0;
const shared_1 = require("@clerk/shared");
const errorThrower = (0, shared_1.buildErrorThrower)({ packageName: '@clerk/react' });
exports.errorThrower = errorThrower;
function __internal__setErrorThrowerOptions(options) {
    errorThrower.setMessages(options).setPackageName(options);
}
exports.__internal__setErrorThrowerOptions = __internal__setErrorThrowerOptions;
