import { SignIn, SignUp } from "@clerk/clerk-react";
import { Center } from "@mantine/core";
import { styleSettings } from "../utils/styleConstants";

export function UserAuthRoute() {
  if(location.pathname === "/signup") {
    return <Center
      maw={styleSettings.authRoute.signUp.maxWidth}
      w={styleSettings.authRoute.signUp.width}
      my={styleSettings.authRoute.signUp.marginY}
    ><SignUp
      appearance={{
        variables: {
          colorBackground: styleSettings.authRoute.signUp.variables.colorBackground,
        },
        elements:{
          formButtonPrimary: {
            color: styleSettings.authRoute.signUp.elements.formButtonPrimary.color,
          }
        },
        layout: {
          // @ts-ignore
          socialButtonsVariant: styleSettings.authRoute.signUp.layout.socialButtonsVariant,
          // @ts-ignore
          socialButtonsPlacement: styleSettings.authRoute.signUp.layout.socialButtonsPlacement
        }
      }}
      routing="path" path="/signup" signInUrl="/signin#" />
    </Center>
  }
  else if(location.pathname === "/signup/verify-email-address") {
    return <Center
      maw={styleSettings.authRoute.signUp.maxWidth}
      w={styleSettings.authRoute.signUp.width}
      my={styleSettings.authRoute.signUp.marginY}
    ><SignUp
      appearance={{
        variables: {
          colorBackground: styleSettings.authRoute.signUp.variables.colorBackground,
        },
        elements:{
          formButtonPrimary: {
            color: styleSettings.authRoute.signUp.elements.formButtonPrimary.color,
          }
        },
        layout: {
          // @ts-ignore
          socialButtonsVariant: styleSettings.authRoute.signUp.layout.socialButtonsVariant,
          // @ts-ignore
          socialButtonsPlacement: styleSettings.authRoute.signUp.layout.socialButtonsPlacement
        }
      }}
      routing="virtual" path="/signup/verify-email-address" signInUrl="/signin#" />
    </Center>
  }
  else {
    return <Center
      maw={styleSettings.authRoute.signIn.maxWidth}
      w={styleSettings.authRoute.signIn.width}
      my={styleSettings.authRoute.signIn.marginY}
    ><SignIn
      appearance={{
        variables: {
          colorBackground: styleSettings.authRoute.signIn.variables.colorBackground,
        },
        elements:{
          formButtonPrimary: {
            color: styleSettings.authRoute.signIn.elements.formButtonPrimary.color,
          }
        },
        layout: {
          // @ts-ignore
          socialButtonsVariant: styleSettings.authRoute.signIn.layout.socialButtonsVariant,
          // @ts-ignore
          socialButtonsPlacement: styleSettings.authRoute.signIn.layout.socialButtonsPlacement
        }
      }}
      routing="virtual" path="/signin" signUpUrl="/signup#" />
    </Center>
  }
}
