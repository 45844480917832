"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deriveState = void 0;
const deriveState = (clerkLoaded, state, initialState) => {
    if (!clerkLoaded && initialState) {
        return deriveFromSsrInitialState(initialState);
    }
    return deriveFromClientSideState(state);
};
exports.deriveState = deriveState;
const deriveFromSsrInitialState = (initialState) => {
    const userId = initialState.userId;
    const user = initialState.user;
    const sessionId = initialState.sessionId;
    const session = initialState.session;
    const organization = initialState.organization;
    const orgId = initialState.orgId;
    const orgRole = initialState.orgRole;
    const orgSlug = initialState.orgSlug;
    const actor = initialState.actor;
    return {
        userId,
        user,
        sessionId,
        session,
        organization,
        orgId,
        orgRole,
        orgSlug,
        actor,
        lastOrganizationInvitation: null,
        lastOrganizationMember: null,
    };
};
const deriveFromClientSideState = (state) => {
    var _a;
    const userId = state.user ? state.user.id : state.user;
    const user = state.user;
    const sessionId = state.session ? state.session.id : state.session;
    const session = state.session;
    const actor = session === null || session === void 0 ? void 0 : session.actor;
    const organization = state.organization;
    const orgId = state.organization ? state.organization.id : state.organization;
    const orgSlug = organization === null || organization === void 0 ? void 0 : organization.slug;
    const membership = organization
        ? (_a = user === null || user === void 0 ? void 0 : user.organizationMemberships) === null || _a === void 0 ? void 0 : _a.find(om => om.organization.id === orgId)
        : organization;
    const orgRole = membership ? membership.role : membership;
    const lastOrganizationInvitation = state.lastOrganizationInvitation;
    const lastOrganizationMember = state.lastOrganizationMember;
    return {
        userId,
        user,
        sessionId,
        session,
        organization,
        orgId,
        orgRole,
        orgSlug,
        actor,
        lastOrganizationInvitation,
        lastOrganizationMember,
    };
};
