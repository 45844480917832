export const styleSettings = {
  authBlur: {
    none: "blur(0px)",
    some: "blur(4px)"
  },
  authRoute: {
    signUp: {
      elements:{
        formButtonPrimary: {
          color: "#fff",
        }
      },
      layout: {
        socialButtonsVariant: "blockButton",
        socialButtonsPlacement: "bottom"
      },
      maxWidth: "60rem",
      marginY: "4rem",
      variables: {
        colorBackground: "#fff",
      },
      width: "100%",
    },
    signIn: {
      elements:{
        formButtonPrimary: {
          color: "#fff",
        }
      },
      layout: {
        socialButtonsVariant: "blockButton",
        socialButtonsPlacement: "top"
      },
      maxWidth: "60rem",
      marginY: "4rem",
      variables: {
        colorBackground: "#fff",
      },
      width: "100%",
    }
  },
  chatRoute: {
    messageCard: {
      marginTop: "xs",
      skeleton: {
        height: 8,
        marginTop: 6,
        radius: "xl",
        width: "70%",
      },
    },
    messagesContainer: {
      className: "prompt_input_container unset_margin styled_input_container",
      paddingTop: "xl",
      paddingBottom: 100,
      stackSpacing: "xs",
    },
    modifierPulldown: {
      marginTop: "0.75rem",
      marginBottom: 0,
    },
    promptSection: {
      paddingY: "lg",
      minRows: 1,
      maxRows: 12,
      sxProp: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        mediaDeclarations: {
          left: 300,
        },
      },
      promptContainer: {
        className: {
          dark: "prompt_input_container unset_margin styled_input_container_dark",
          light: "prompt_input_container unset_margin styled_input_container_light",
        },
        gap: "sm",
      },
    },
  },
  components: {
    modal: {
      defaultProps: {
        padding: "xl",
      },
      styles: {
        title: {
          fontSize: "1.2rem",
          fontWeight: 600,
        },
      },
    },
    modalRoot: {
      defaultProps: {
        centered: true,
      },
    },
    overlay: {
      defaultProps: {
        opacity: 0.6,
        blur: 6,
      },
    },
    input: {
      defaultProps: {
        variant: "filled",
      },
    },
    inputWrapper: {
      styles: {
        label: {
          marginBottom: 4,
        },
      },
    },
  },
  dark: {
    accordionTriggerButton: {
      boxShadow: 0,
      backgroundColor: "#fab0071a",
      color: "#fff",
      focus: {
        boxShadow: "none"
      },
      hoverActive: {
        backgroundColor: "#fab0079a"
      },
    },
    avatarBox: {
      backgroundColor: "transparent",
    },
    badge: {
      color: "#fff",
    },
    border: "#A8A29E9a",
    card: {
      backgroundColor: "#44403C",
      color: "#fff",
    },
    chatBg: "#78716C",
    chatIconBg: "#ffd43b",
    codeBg: "#44403C",
    formButtonPrimary: {
      backgroundColor: "#fab007",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#fff",
    },
    formButtonReset: {
      backgroundColor: "#fab0075a",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#fff",
    },
    globalBg: "#333",
    headerBg: "#44403C",
    inputBg: "#FAFAF9",
    inputColor: "#292524",
    navLeft: "#57534E",
    navLeftHover: "#78716C",
    profileSectionPrimaryButton: {
      backgroundColor: "#fab0071a",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#fff",
    },
    selectColor: "#E7E5E4",
    selectBackgroundColor: "#57534E",
    textColor: "#FAFAF9",
    themeToggle: "#ffd43b",
    userButtonTrigger: {
      focus: {
        boxShadow: "none",
      },
    },

  },
  indexRoute: {
    badge: {
      size: "lg",
      marginBottom: "lg",
    },
    downloadExtention: {
      button: {
        componentType: "a",
        leftIconSize: 20,
        size: "md",
        text:{
          size: "xs",
        },
        variant: "outline",
      },
      group: {
        marginTop: 4
      },
      size: "xl",
    },
    getStartedGrid: {
      marginTop: 30,
      cols: 3,
      spacing: 80,
      breakpoints: {
        maxWidth: "md",
        cols: 1
      }
    },
    getStartedStep: {
      outerCircle: {
        color: {
          dark: "orange.4",
          light: "orange.3"
        },
        radius: 50,
        size: 84,
        variant: "outline",
      },
      icon: {
        size: 60,
        stroke: 0.5,
      },
      innerCircle: {
        color: {
          dark: "#57534E",
          light: "#E7E5E4"
        },
        radius: 50,
        size: 78,
        variant: "filled",
      },
      step: {
        title: {
          marginBottom: 20,
          marginTop: "sm",
        },
        description: {
          size: "sm",
          sxProp: {
            lineHeight: 1.6
          },
        }
      },
    },
    header: {
      marginTop: 4,
      size: "xl",
    },
    innerContainer: {
      size: "sm",
    },
    outerContainer: {
      paddingY: "4rem",
      sxProp: {
        height: "100%"
      },
      className: "unset_margin vertical_align_center index_message",
    },
  },
  light: {
    accordionTriggerButton: {
      boxShadow: 0,
      backgroundColor: "#fab0071a",
      color: "#666",
      focus: {
        boxShadow: "none"
      },
      hoverActive: {
        backgroundColor: "#fab0079a"
      },
    },
    avatarBox: {
      backgroundColor: "transparent",
    },
    badge: {
      color: "#666",
    },
    border: "#E7E5E4",
    card: {
      backgroundColor: "#E7E5E4",
      color: "#666",
    },
    chatBg: "#FAFAF9",
    chatIconBg: "#D6D3D1",
    codeBg: "#F5F5F4",
    formButtonPrimary: {
      backgroundColor: "#fab007",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#666",
    },
    formButtonReset: {
      backgroundColor: "#fab0073a",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#666",
    },
    globalBg: "#ddd",
    header: "#44403C",
    headerBg: "#F5F5F4",
    inputBg: "#F5F5F4",
    inputColor: "#292524",
    navLeft: "#E7E5E4",
    navLeftHover: "#F5F5F4",
    profileSectionPrimaryButton: {
      backgroundColor: "#fab0071a",
      hoverFocusActive: {
        backgroundColor: "#fab0079a"
      },
      focus: {
        boxShadow: "none",
      },
      color: "#666",
    },
    selectColor: "#fff",
    selectBackgroundColor: "#E7E5E4",
    textColor: "#1C1917",
    themeToggle: "#57534E",
    userButtonTrigger: {
      focus: {
        boxShadow: "none",
      },
    },
  },
  primaryColor: "yellow",
  warmgray:<[(string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?]> [
    "#FAFAF9",
    "#F5F5F4",
    "#E7E5E4",
    "#D6D3D1",
    "#A8A29E",
    "#78716C",
    "#57534E",
    "#44403C",
    "#292524",
    "#1C1917"],
}

