import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { dark } from '@clerk/themes';
import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import { createHashHistory, ReactLocation, Router } from "@tanstack/react-location";

import { styleSettings } from "../utils/styleConstants";

import { ChatRoute } from "../routes/ChatRoute";
import { IndexRoute } from "../routes/IndexRoute";
import { UserAuthRoute } from "../routes/UserAuthRoute";
import { Layout } from "./Layout";

const history = createHashHistory();
const location = new ReactLocation({ history });



export function App({ authLanguage }: { authLanguage: Record<string, any> }) {

  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Clerk Key")
  }
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  const queryClient = new QueryClient();
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: prefersDark ? "dark" : "light",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  };

  return (
    <QueryClientProvider client={queryClient}>

      <ClerkProvider
        publishableKey={clerkPubKey}
        localization={authLanguage}
        appearance={
          {
            // @ts-ignore
            baseTheme: colorScheme === "dark" ? dark : null,
            variables: {
              colorBackground: styleSettings[colorScheme].headerBg,
              colorAlphaShade: styleSettings[colorScheme].themeToggle,
            },
            elements: {
              accordionTriggerButton: {
                boxShadow: styleSettings[colorScheme].accordionTriggerButton.boxShadow,
                backgroundColor: styleSettings[colorScheme].accordionTriggerButton.backgroundColor,
                "&:hover, &:active": {
                  backgroundColor: styleSettings[colorScheme].accordionTriggerButton.hoverActive.backgroundColor,
                },
                "&:focus": {
                  boxShadow: styleSettings[colorScheme].accordionTriggerButton.focus.boxShadow,
                },
                color: styleSettings[colorScheme].accordionTriggerButton.color,
              },
              avatarBox: {
                backgroundColor: styleSettings[colorScheme].avatarBox.backgroundColor,
              },
              badge: {
                color: styleSettings[colorScheme].badge.color,
              },
              card: {
                backgroundColor: styleSettings[colorScheme].card.backgroundColor,
                color: styleSettings[colorScheme].card.color,
              },
              formButtonPrimary: {
                backgroundColor: styleSettings[colorScheme].formButtonPrimary.backgroundColor,
                "&:hover, &:focus, &:active": {
                  backgroundColor: styleSettings[colorScheme].formButtonPrimary.hoverFocusActive.backgroundColor
                },
                "&:focus": {
                  boxShadow: styleSettings[colorScheme].formButtonPrimary.focus.boxShadow,
                },
                color: styleSettings[colorScheme].formButtonPrimary.color,
              },
              formButtonReset: {
                backgroundColor: styleSettings[colorScheme].formButtonReset.backgroundColor,
                "&:hover, &:focus, &:active": {
                  backgroundColor: styleSettings[colorScheme].formButtonReset.hoverFocusActive.backgroundColor
                },
                "&:focus": {
                  boxShadow: styleSettings[colorScheme].formButtonReset.focus.boxShadow,
                },
                color: styleSettings[colorScheme].formButtonReset.color,
              },
              profileSectionPrimaryButton: {
                backgroundColor: styleSettings[colorScheme].profileSectionPrimaryButton.backgroundColor,
                "&:hover, &:focus, &:active": {
                  backgroundColor: styleSettings[colorScheme].profileSectionPrimaryButton.hoverFocusActive.backgroundColor
                },
                "&:focus": {
                  boxShadow: styleSettings[colorScheme].profileSectionPrimaryButton.focus.boxShadow,
                },
                color: styleSettings[colorScheme].profileSectionPrimaryButton.color,
              },
              userButtonTrigger: {
                "&:focus": {
                  boxShadow: styleSettings[colorScheme].userButtonTrigger.focus.boxShadow,
                },
              },
            },
          }
        }
      >
        <SignedIn>

          <Router
            location={location}
            routes={[
              { path: "/", element: <IndexRoute /> },
              { path: "/chats/:chatId", element: <ChatRoute /> },
            ]}
          >
            <ColorSchemeProvider
              colorScheme={colorScheme}
              toggleColorScheme={toggleColorScheme}
            >
              <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                withCSSVariables
                theme={{
                  colorScheme,
                  colors: {
                    "warmgray": styleSettings.warmgray,
                  },
                  primaryColor: styleSettings.primaryColor,
                  globalStyles: (theme) => ({
                    body: {
                      backgroundColor: styleSettings[theme.colorScheme].globalBg,
                    },
                  }),
                  components: {
                    Modal: {
                      defaultProps: {
                        padding: styleSettings.components.modal.defaultProps.padding,
                      },
                      styles: {
                        title: {
                          fontSize: styleSettings.components.modal.styles.title.fontSize,
                          fontWeight: styleSettings.components.modal.styles.title.fontWeight,
                        },
                      },
                    },
                    ModalRoot: {
                      defaultProps: {
                        centered: styleSettings.components.modalRoot.defaultProps.centered,
                      },
                    },
                    Overlay: {
                      defaultProps: {
                        opacity: styleSettings.components.overlay.defaultProps.opacity,
                        blur: styleSettings.components.overlay.defaultProps.blur,
                      },
                    },
                    // Input: {
                    //   defaultProps: {
                    //     variant: styleSettings.components.input.defaultProps.variant,
                    //   },
                    // },
                    InputWrapper: {
                      styles: {
                        label: {
                          marginBottom: styleSettings.components.inputWrapper.styles.label.marginBottom,
                        },
                      },
                    },
                    Code: {
                      styles: (theme) => ({
                        root: {
                          fontSize: theme.fontSizes.sm,
                          backgroundColor: styleSettings[theme.colorScheme].codeBg,
                        },
                      }),
                    },
                  },
                }}
              >
                <Layout authBlur={styleSettings.authBlur.none} />
                <Notifications />
              </MantineProvider>
            </ColorSchemeProvider>
          </Router>
        </SignedIn>
        <SignedOut>
          <Router
            location={location}
            routes={[
              { element: <UserAuthRoute /> },
            ]}
          >
            <ColorSchemeProvider
              colorScheme={colorScheme}
              toggleColorScheme={toggleColorScheme}
            >
              <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                withCSSVariables
                theme={{
                  colorScheme,
                  colors: {
                    warmgray: styleSettings.warmgray,
                  },
                  components: {
                    Modal: {
                      defaultProps: {
                        padding: styleSettings.components.modal.defaultProps.padding,
                      },
                      styles: {
                        title: {
                          fontSize: styleSettings.components.modal.styles.title.fontSize,
                          fontWeight: styleSettings.components.modal.styles.title.fontWeight,
                        },
                      },
                    },
                    ModalRoot: {
                      defaultProps: {
                        centered: styleSettings.components.modalRoot.defaultProps.centered,
                      },
                    },
                    Overlay: {
                      defaultProps: {
                        opacity: styleSettings.components.overlay.defaultProps.opacity,
                        blur: styleSettings.components.overlay.defaultProps.blur,
                      },
                    },
                    // Input: {
                    //   defaultProps: {
                    //     variant: styleSettings.components.input.defaultProps.variant,
                    //   },
                    // },
                    InputWrapper: {
                      styles: {
                        label: {
                          marginBottom: styleSettings.components.inputWrapper.styles.label.marginBottom,
                        },
                      },
                    },
                    Code: {
                      styles: (theme) => ({
                        root: {
                          fontSize: theme.fontSizes.sm,
                          backgroundColor: styleSettings[theme.colorScheme].codeBg,
                        },
                      }),
                    },
                  },
                  globalStyles: (theme) => ({
                    body: {
                      backgroundColor: styleSettings[theme.colorScheme].globalBg,
                    },
                  }),
                  primaryColor: styleSettings.primaryColor,
                }}
              >
                <Layout authBlur={styleSettings.authBlur.some} />
                <Notifications />
              </MantineProvider>
            </ColorSchemeProvider>
          </Router>
        </SignedOut>
      </ClerkProvider>
    </QueryClientProvider>
  );
}