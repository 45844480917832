import { defaultLocale } from "./languageConstants";
import { supportedLocalesHash } from "./languageConstants";

export function getCookie(name: string) {
  if (typeof document !== 'undefined') {
    console.log("selectedLocale", name)
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
  }
  else {
    console.log("document undefined")

  }
  return null;
}

export function getLocaleFromCookie() {
  const selectedLocale = getCookie("selected_locale") as string;
  const supportedLocalesKeys = Object.keys(supportedLocalesHash);
  const isValidLocale = supportedLocalesKeys.includes(selectedLocale);
  return isValidLocale ? selectedLocale : defaultLocale;
}
