import {ActionIcon, Flex, Menu, rem, useMantineTheme} from "@mantine/core";
import { IconDotsVertical, IconMessages } from "@tabler/icons-react";
import { Link } from "@tanstack/react-location";
import { useMemo } from "react";


import { styleSettings } from "../utils/styleConstants";

import { Chat, db } from "../db";

import { useChatId } from "../hooks/useChatId";
import { DeleteChatModal } from "./DeleteChatModal";
import { EditChatModal } from "./EditChatModal";
import { MainLink } from "./MainLink";

export function Chats({ chats, search }: { chats: Array<Chat>|undefined, search: string }) {
  const theme = useMantineTheme();
  const chatId = useChatId();
  const filteredChats = useMemo(
    () =>
      (chats ?? []).filter((chat) => {
        if (!search) return true;
        if(chat.description.toLowerCase().includes(search)) {
          return chat.id;
        };
      }),
    [chats, search]
  );

  return (
    <>
      {filteredChats.length === 0 ? (
        <div className="look_arrow look_bounce">
          <a className="fa fa-arrow-down fa-2x" href="#"></a>
        </div>
      ) : (
        filteredChats.map((chat) => (
          <Flex
            key={chat.id}
            className={chatId === chat.id ? "active" : undefined}
            sx={(theme) => ({
              marginTop: 1,
              boxShadow: "0px 0.1px 0px 0px rgba(255, 255, 255, 0.4)",
              "&:hover": {
                backgroundColor: styleSettings[theme.colorScheme].headerBg,
              },
              "&.active": {
                backgroundColor: styleSettings[theme.colorScheme].chatBg,
              },
            })}
          >
            <Link to={`/chats/${chat.id}`} style={{  flex: 1 }}>
              <MainLink
                icon={<IconMessages size="1rem" />}
                color={styleSettings[theme.colorScheme].chatIconBg}
                chatId={chat.id}
                label={chat.description}
              />
            </Link>
            <Menu shadow="md" width={200} keepMounted>
              <Menu.Target>
                <ActionIcon sx={{ height: "auto" }}>
                  <IconDotsVertical size={20} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <EditChatModal chat={chat}>
                  <Menu.Item>Edit</Menu.Item>
                </EditChatModal>
                <DeleteChatModal chat={chat}>
                  <Menu.Item>Delete</Menu.Item>
                </DeleteChatModal>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        ))
      )}
    </>
  );
}
