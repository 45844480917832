import {
  useUser,
  UserButton,
} from "@clerk/clerk-react";
import {
  ActionIcon,
  AppShell,
  Aside,
  Box,
  Burger,
  Button,
  Center,
  Flex,
  Footer,
  Group,
  Header,
  Image,
  Kbd,
  MediaQuery,
  Navbar,
  rem,
  ScrollArea,
  SegmentedControl,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandGithub,
  IconBrandTwitter,
  IconDatabase,
  IconProgressHelp,
  IconMessage,
  IconMoonStars,
  IconPlus,
  IconSearch,
  IconSettings,
  IconSunHigh,
  IconSun,
  IconX,
} from "@tabler/icons-react";
import {
  createHashHistory,
  ReactLocation,
  useNavigate,
  Link,
  Outlet,
  useRouter } from "@tanstack/react-location";

import { useLiveQuery } from "dexie-react-hooks";
import { nanoid } from "nanoid";

import { useEffect, useState } from "react";

import { Chat, db } from "../db";
import { useChatId } from "../hooks/useChatId";

import { Chats } from "./Chats";

import { CreatePromptModal } from "./CreatePromptModal";
import { DatabaseModal } from "./DatabaseModal";

import { Prompts } from "./Prompts";
import { SettingsModal } from "./SettingsModal";
import { Variables } from "./Variables";

import { createStyles } from '@mantine/core';

// @ts-ignore
import companyLogo from "../assets/logo.png"
import ReactMarkdown from "react-markdown";
import children = ReactMarkdown.propTypes.children;
import { styleSettings } from "../utils/styleConstants";





const history = createHashHistory();
const location = new ReactLocation({ history });

declare global {
  interface Window {
    todesktop?: any;
  }
}

export function Layout({authBlur}: {authBlur: string}) {

  const { isLoaded, isSignedIn, user } = useUser();

  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const [opened, setOpened] = useState(false);
  const [tab, setTab] = useState<"Chats" | "Prompts" | "Favourites">("Chats");
  const [previousTab, setPreviousTab] = useState<String | null>("Chat");
  const [search, setSearch] = useState("");


  const navigate = useNavigate();
  const router = useRouter();

  const chatId = useChatId();

  // get user details from db
  //  chats prompts, settings, etc







  const chat = useLiveQuery(async () => {
    if (!chatId || !isLoaded || !isSignedIn) return null;
    return db.chats.get(chatId);
  }, [chatId]);


  const chats = useLiveQuery(() => {
    // TODO auth testing
    // if (!isLoaded || !isSignedIn && false === true) {
    if (!isLoaded || !isSignedIn) {
      console.log('NOT logged in')
      return [];
    }
    else {
      console.log('logged in')
      return db.chats.orderBy("createdAt").reverse().toArray();
    }
  });

  const prompts = useLiveQuery(() =>
    db.prompts.orderBy("createdAt").reverse().toArray()
  );

  const favourites = useLiveQuery(() =>
    db.favourites.orderBy("createdAt").reverse().toArray()
  );








  const handleTabChange = (nextTab: "Chats" | "Prompts" | "Favourites") => {
    const currentTab = tab.slice(0, -1);

    // Update the state values
    setPreviousTab(currentTab);
    setTab(nextTab);
  }

  const rightSectionKbd = (
    <Flex align="center">
      <Kbd mr={5}>Ctrl</Kbd>
      <span>+</span>
      <Kbd ml={5}>S</Kbd>
    </Flex>
  );

  const searchCancel = (
    <ActionIcon onClick={() => setSearch("")}>
      <IconX
        opacity={1}
        color={styleSettings[theme.colorScheme].inputColor}
        size={20} />{" "}
    </ActionIcon>
  );

  const border = `${rem(1)} dotted ${styleSettings[theme.colorScheme].border}`;


  const headerContent = (tab: String) => {
    console.log(tab)


    // const myCookieValue = cookies['myCookie'];

    // Use the cookie value as needed
    // console.log("session cookie", cookies["__session"]);
    // console.log("burb cookie", cookies["burb"]);

    if(tab === "Chats" && chat) {
      return (
        `${chat.description} - ${chat.totalTokens ?? 0} tokens ~ $${(
          ((chat.totalTokens ?? 0) * 0.002) /
          1000
        ).toFixed(5)}`
      )
    }
    else if(tab === "Chats") {
      return "Welcome to expertGPT"
    }
    else {
      return null;
    }
  };


  const useStyles = createStyles((theme) => ({
    buttonJustify: {
      [theme.fn.smallerThan('sm')]: {
        justifyContent: "right",
      },
      [theme.fn.largerThan('sm')]: {
        justifyContent: "left",
      },
    },
  }));

  const { classes } = useStyles();

  useEffect(() => {
    setOpened(false);
  }, [router.state.location]);

  return (
    <AppShell
      className={`${colorScheme}-theme`}
      navbarOffsetBreakpoint="sm"
      sx={(theme) => ({
        main: {
          background: styleSettings[theme.colorScheme].chatBg,
        },
      })}
      navbar={
        <Navbar
          width={{ md: 300 }}
          hiddenBreakpoint="md"
          hidden={!opened}

          style={{
            border: 0,
            filter: authBlur,
          }}
        >
          {/*logo*/}
          <Navbar.Section className="app-region-drag">
            <Box
              sx={(theme) => ({
                background: styleSettings[theme.colorScheme].headerBg,
              })}
              style={{
                height: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRight: 0,
                borderBottom: border,
              }}
            >
              <Link
                to="/"
                className="app-region-no-drag"
                style={{ marginTop: 0, padding: 4 }}
              >
                <Image maw={240} mx="auto" radius="md" src={companyLogo} alt="expertGPT" />
              </Link>
              {/*<MediaQuery largerThan="md" styles={{ display: "none" }}>*/}
              {/*    <Burger*/}
              {/*        opened={opened}*/}
              {/*        onClick={() => setOpened((o) => !o)}*/}
              {/*        size="sm"*/}
              {/*        color={theme.colors.gray[6]}*/}
              {/*        className="app-region-no-drag"*/}
              {/*        sx={{ position: "fixed", right: 16 }}*/}
              {/*    />*/}
              {/*</MediaQuery>*/}
            </Box>
          </Navbar.Section>
          {/*tabs*/}
          <Navbar.Section
            sx={(theme) => ({
              padding: rem(4),
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
              borderBottom: 0,
            })}
          >
            <SegmentedControl
              className={
                theme.colorScheme === "dark"
                  ? "segment_control_dark"
                  : "segment_control_light"
              }
              sx={(theme) => ({
                background: styleSettings[theme.colorScheme].navLeft,
              })}
              color="warmgray"
              transitionDuration={100}
              fullWidth
              value={tab}
              onChange={(value) => handleTabChange(value as typeof tab)}
              data={["Chats", "Prompts", "Favourites"]}
            />
          </Navbar.Section>
          {/*search*/}
          <Navbar.Section
            className={`search_input_container styled_input_container_${theme.colorScheme}`}
            sx={(theme) => ({
              padding: rem(4),
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
              borderBottom: 0,
            })}
          >
            <TextInput
              color={styleSettings[theme.colorScheme].inputColor}
              variant="unstyled"
              radius={88}
              placeholder={
                tab === "Chats"
                  ? "Search Previous Chats"
                  : tab === "Prompts"
                    ? "Search Prompts"
                    : "Search Your Favourites"
              }
              className={
                tab === "Chats"
                  ? chats?.length ? `input_enabled_${theme.colorScheme}` : `input_disabled_${theme.colorScheme}`
                  : tab === "Prompts"
                    ? prompts?.length ? `input_enabled_${theme.colorScheme}` : `input_disabled_${theme.colorScheme}`
                    : favourites?.length ? `input_enabled_${theme.colorScheme}` : `input_disabled_${theme.colorScheme}`
              }
              value={search}
              onChange={(event) =>
                setSearch(event.currentTarget.value.toLowerCase())
              }
              sx={(theme) => ({
                paddingInline: 4,
                margin: 6,
                border: "1px transparent",
                borderRadius: "0.25rem",
                background: styleSettings[theme.colorScheme].inputBg,
                input: {
                  paddingRight: "2.25rem",
                  color: styleSettings[theme.colorScheme].inputColor,
                }
              })}
              icon={<IconSearch
                color={styleSettings[theme.colorScheme].inputColor}
                opacity={1}
                size={20}
              />}
              rightSection={
                !!search && searchCancel
              }
            />
          </Navbar.Section>
          {/*chats/prompts/favourites*/}
          <Navbar.Section
            grow component={ScrollArea}
            sx={(theme) => ({
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
            })}
          >
            {tab === "Chats" && <Chats chats={chats} search={search} />}
            {tab === "Prompts" && (
              <Prompts search={search} onPlay={() => setTab("Chats")} />
            )}
            {tab === "Favourites" && (
              <Prompts search={search} onPlay={() => setTab("Favourites")} />
            )}
          </Navbar.Section>
          {/*new button*/}
          <Navbar.Section
            sx={(theme) => ({
              padding: rem(4),
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
              borderBottom: 0,
            })}
          >
            <Variables />
          </Navbar.Section>
          <Navbar.Section
            sx={(theme) => ({
              padding: rem(4),
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
              borderBottom: 0,
            })}
          >
            <Box sx={{ padding: 4 }}>
              {tab === "Chats" && (

                <Button
                  classNames={{ inner: classes.buttonJustify }}
                  fullWidth
                  leftIcon={<IconPlus size={20} />}
                  onClick={() => {
                    const id = nanoid();
                    // TODO indexeddb
                    db.chats.add({
                      id,
                      description: "New Chat",
                      totalTokens: 0,
                      createdAt: new Date(),
                    });
                    navigate({ to: `/chats/${id}` });
                  }}

                  // onClick={() => {
                  //   const id = nanoid();
                  //   db.chats.add({
                  //     id,
                  //     description: "New Chat",
                  //     totalTokens: 0,
                  //     createdAt: new Date(),
                  //   });
                  //   navigate({ to: `/chats/${id}` });
                  // }}
                >
                  New Chat
                </Button>
              )}
              {tab === "Prompts" && <CreatePromptModal />}
              {tab === "Favourites" && (
                <Button
                  classNames={{ root: "disable_fadeout", inner: classes.buttonJustify }}
                  // styles={{
                  //     inner: { justifyContent: theme.fn.largerThan('sm') ? "left" : "right" },
                  // }}
                  // sx={(theme) => ({
                  //     root: {
                  //         [theme.fn.largerThan('sm')]: { justifyContent: "left" },
                  //         [theme.fn.smallerThan('sm')]: { justifyContent: "right" },
                  //     },
                  //
                  //
                  //
                  //
                  //
                  //     // background: theme.colors.gray[0],
                  //     // padding: theme.spacing.md,
                  //     //
                  //     // '@media (max-width: 40em)': {
                  //     //     padding: theme.spacing.sm,
                  //     // },
                  // })}
                  fullWidth
                  leftIcon={<IconPlus size={20} />}
                  onClick={() => {
                    return null;
                  }}
                >
                  New {previousTab}
                </Button>
              )}
            </Box>
          </Navbar.Section>
          {/*settings*/}
          <Navbar.Section
            sx={{
              background: styleSettings[theme.colorScheme].navLeft,
              borderRight: border,
              borderTop: border,
            }}
            p="xs"
          >
            <Center>




              <Tooltip
                label={colorScheme === "dark" ? "Light Mode" : "Dark Mode"}
              >
                <ActionIcon
                  sx={(theme) => ({
                    color: styleSettings[theme.colorScheme].themeToggle,
                    flex: 1,

                    "&:hover": {
                      backgroundColor: styleSettings[theme.colorScheme].navLeftHover,
                    },

                  })}
                  size="xl"
                  onClick={() => toggleColorScheme()}
                >
                  {colorScheme === "dark" ? (
                    <IconSunHigh size={20} />
                  ) : (
                    <IconMoonStars size={20} />
                  )}
                </ActionIcon>
              </Tooltip>
              <SettingsModal>
                <Tooltip label="Settings">
                  <ActionIcon
                    sx={(theme) => ({
                      flex: 1,
                      "&:hover": {
                        backgroundColor: styleSettings[theme.colorScheme].navLeftHover,
                      },
                    })}
                    size="xl"
                  >
                    <IconSettings size={20} />
                  </ActionIcon>
                </Tooltip>
              </SettingsModal>
              <SettingsModal>
                <Tooltip label="Help">
                  <ActionIcon
                    sx={(theme) => ({
                      flex: 1,
                      "&:hover": {
                        backgroundColor: styleSettings[theme.colorScheme].navLeftHover,
                      },
                    })}
                    size="xl"
                  >
                    <IconProgressHelp size={20} />
                  </ActionIcon>
                </Tooltip>
              </SettingsModal>


              <Tooltip label="Account">
                <ActionIcon
                  sx={(theme) => ({
                    flex: 1,
                    // boxShadow:
                    //   theme.colorScheme === "dark"
                    //     ? "rgba(65, 65, 65, 0.7 ) 0px 0px 4px 1px"
                    //     : "rgba(0, 0, 0, 0.5 ) 0px 0px 3px 1px",
                    // backgroundColor: colourSet[theme.colorScheme].border,
                    "&:hover": {
                      backgroundColor: styleSettings[theme.colorScheme].navLeftHover,
                    },
                  })}
                  size="xl"
                >
                  <UserButton
                    afterSignOutUrl={"https://getexpertGPT.com"}
                  />
                </ActionIcon>
              </Tooltip>






              {/*<DatabaseModal>*/}
              {/*  <Tooltip label="Database">*/}
              {/*    <ActionIcon sx={{ flex: 1 }} size="xl">*/}
              {/*      <IconDatabase size={20} />*/}
              {/*    </ActionIcon>*/}
              {/*  </Tooltip>*/}
              {/*</DatabaseModal>*/}
              {/*<Tooltip label="Source Code">*/}
              {/*  <ActionIcon*/}
              {/*    component="a"*/}
              {/*    href="https://github.com/deiucanta/chatpad"*/}
              {/*    target="_blank"*/}
              {/*    sx={{ flex: 1 }}*/}
              {/*    size="xl"*/}
              {/*  >*/}
              {/*    <IconBrandGithub size={20} />*/}
              {/*  </ActionIcon>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label="Follow on Twitter">*/}
              {/*  <ActionIcon*/}
              {/*    component="a"*/}
              {/*    href="https://twitter.com/deiucanta"*/}
              {/*    target="_blank"*/}
              {/*    sx={{ flex: 1 }}*/}
              {/*    size="xl"*/}
              {/*  >*/}
              {/*    <IconBrandTwitter size={20} />*/}
              {/*  </ActionIcon>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label="Give Feedback">*/}
              {/*  <ActionIcon*/}
              {/*    component="a"*/}
              {/*    href="https://feedback.chatpad.ai"*/}
              {/*    onClick={(event) => {*/}
              {/*      if (window.todesktop) {*/}
              {/*        event.preventDefault();*/}
              {/*        window.todesktop.contents.openUrlInBrowser(*/}
              {/*          "https://feedback.chatpad.ai"*/}
              {/*        );*/}
              {/*      }*/}
              {/*    }}*/}
              {/*    target="_blank"*/}
              {/*    sx={{ flex: 1 }}*/}
              {/*    size="xl"*/}
              {/*  >*/}
              {/*    <IconMessage size={20} />*/}
              {/*  </ActionIcon>*/}
              {/*</Tooltip>*/}
            </Center>
          </Navbar.Section>
        </Navbar>
      }
      header={

        <Header
          height={80}
          style={{
            backgroundColor: styleSettings[theme.colorScheme].headerBg,
          }}

        >
          <Header
            height={80}
            p="xs"
            className="app-region-drag"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: border,
              filter: authBlur,
              maxWidth: "64rem",
              backgroundColor: styleSettings[theme.colorScheme].headerBg,
            }}

          >
            <div
              style={{
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",


                flexGrow: "1",
                textAlign: "center",
                // height: "100%",
                maxWidth: "60rem",
              }}
            >
              {headerContent(tab)}
            </div>
          </Header>
        </Header>
      }
      // aside={
      //   <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
      //     <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
      //       <Text>Application sidebar</Text>
      //     </Aside>
      //   </MediaQuery>
      // }
      // footer={
      //   <Footer height={60} p="md">
      //     Application footer
      //   </Footer>
      // }
      layout="alt"
      padding={0}
    >
      <MediaQuery largerThan="md" styles={{ display: "none" }}>
        <Burger
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size="sm"
          color={theme.colors.warmgray[6]}
          className="app-region-no-drag"
          sx={{ position: "fixed", top: 26, right: 16, zIndex: 100 }}
        />
      </MediaQuery>
      <Outlet />

    </AppShell>

  );
}
