import { getLocaleFromCookie } from "../utils/cookies";

export const defaultLocale = "fr";

// if this changes, change: web/js/script.js and services/convertClerkLocales.js
export const supportedLocalesHash = {
  "af": {
    "name": "Afrikaans",
    "nativeName": "Afrikaans"
  },
  "ar": {
    "name": "Arabic",
    "nativeName": "العربية"
  },
  "az": {
    "name": "Azerbaijani",
    "nativeName": "Azərbaycan"
  },
  "bg": {
    "name": "Bulgarian",
    "nativeName": "Български"
  },
  "bo": {
    "name": "Tibetan",
    "nativeName": "བོད་སྐད་"
  },
  "bs": {
    "name": "Bosnian",
    "nativeName": "Bosnian"
  },
  "ca": {
    "name": "Catalan",
    "nativeName": "Català"
  },
  "cs": {
    "name": "Czech",
    "nativeName": "Čeština"
  },
  "cy": {
    "name": "Welsh",
    "nativeName": "Cymraeg"
  },
  "da": {
    "name": "Danish",
    "nativeName": "Dansk"
  },
  "de": {
    "name": "German",
    "nativeName": "Deutsch"
  },
  "el": {
    "name": "Greek",
    "nativeName": "Ελληνικά"
  },
  "en-CA": {
    "name": "English",
    "nativeName": "English"
  },
  "en-GB": {
    "name": "English",
    "nativeName": "English"
  },
  "en-US": {
    "name": "English",
    "nativeName": "English"
  },
  "es": {
    "name": "Spanish",
    "nativeName": "Español"
  },
  "es-MX": {
    "name": "Spanish",
    "nativeName": "Español"
  },
  "et": {
    "name": "Estonian",
    "nativeName": "Eesti"
  },
  "fi": {
    "name": "Finnish",
    "nativeName": "Suomi"
  },
  "fil": {
    "name": "Filipino",
    "nativeName": "Filipino"
  },
  "fr": {
    "name": "French",
    "nativeName": "Français"
  },
  "fr-CA": {
    "name": "French (Canada)",
    "nativeName": "Français (Canada)"
  },
  "ga": {
    "name": "Irish",
    "nativeName": "Gaeilge"
  },
  "he": {
    "name": "Hebrew",
    "nativeName": "עברית"
  },
  "hi": {
    "name": "Hindi",
    "nativeName": "हिन्दी"
  },
  "hr": {
    "name": "Croatian",
    "nativeName": "Hrvatski"
  },
  "hu": {
    "name": "Hungarian",
    "nativeName": "Magyar"
  },
  "hy": {
    "name": "Armenian",
    "nativeName": "Հայերեն"
  },
  "id": {
    "name": "Indonesian",
    "nativeName": "Indonesia"
  },
  "is": {
    "name": "Icelandic",
    "nativeName": "Íslenska"
  },
  "it": {
    "name": "Italian",
    "nativeName": "Italiano"
  },
  "ja": {
    "name": "Japanese",
    "nativeName": "日本語"
  },
  "ko": {
    "name": "Korean",
    "nativeName": "한국어"
  },
  "lt": {
    "name": "Lithuanian",
    "nativeName": "Lietuvių"
  },
  "lv": {
    "name": "Latvian",
    "nativeName": "Latviešu"
  },
  "mk": {
    "name": "Macedonian",
    "nativeName": "Македонски"
  },
  "mn-Mong": {
    "name": "Mongolian (Traditional)",
    "nativeName": "ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ"
  },
  "ms": {
    "name": "Malay",
    "nativeName": "Melayu"
  },
  "mt": {
    "name": "Maltese",
    "nativeName": "Malti"
  },
  "my": {
    "name": "Myanmar (Burmese)",
    "nativeName": "မြန်မာ"
  },
  "nb": {
    "name": "Norwegian",
    "nativeName": "Norsk Bokmål"
  },
  "nl": {
    "name": "Dutch",
    "nativeName": "Nederlands"
  },
  "pa": {
    "name": "Punjabi",
    "nativeName": "ਪੰਜਾਬੀ"
  },
  "pl": {
    "name": "Polish",
    "nativeName": "Polski"
  },
  "ps": {
    "name": "Pashto",
    "nativeName": "پښتو"
  },
  "pt": {
    "name": "Portuguese",
    "nativeName": "Português"
  },
  "pt-PT": {
    "name": "Portuguese",
    "nativeName": "Português"
  },
  "ro": {
    "name": "Romanian",
    "nativeName": "Română"
  },
  "ru": {
    "name": "Russian",
    "nativeName": "Русский"
  },
  "sk": {
    "name": "Slovak",
    "nativeName": "Slovenčina"
  },
  "sl": {
    "name": "Slovenian",
    "nativeName": "Slovenščina"
  },
  "sm": {
    "name": "Samoan",
    "nativeName": "Gagana Sāmoa"
  },
  "so": {
    "name": "Somali",
    "nativeName": "Soomaali"
  },
  "sq": {
    "name": "Albanian",
    "nativeName": "Shqip"
  },
  "sr-Cyrl": {
    "name": "Serbian (Cyrillic)",
    "nativeName": "Српски (ћирилица)"
  },
  "sr-Latn": {
    "name": "Serbian (Latin)",
    "nativeName": "Srpski (latinica)"
  },
  "sv": {
    "name": "Swedish",
    "nativeName": "Svenska"
  },
  "sw": {
    "name": "Swahili",
    "nativeName": "Kiswahili"
  },
  "ta": {
    "name": "Tamil",
    "nativeName": "தமிழ்"
  },
  "th": {
    "name": "Thai",
    "nativeName": "ไทย"
  },
  "tlh-Latn": {
    "name": "Klingon (Latin)",
    "nativeName": "Klingon (Latin)"
  },
  "tlh-Piqd": {
    "name": "Klingon (pIqaD)",
    "nativeName": "Klingon (pIqaD)"
  },
  "tr": {
    "name": "Turkish",
    "nativeName": "Türkçe"
  },
  "uk": {
    "name": "Ukrainian",
    "nativeName": "Українська"
  },
  "vi": {
    "name": "Vietnamese",
    "nativeName": "Tiếng Việt"
  },
  "yue": {
    "name": "Cantonese (Traditional)",
    "nativeName": "粵語 (繁體)"
  },
  "zh-Hans": {
    "name": "Chinese Simplified",
    "nativeName": "中文 (简体)"
  },
  "zh-Hant": {
    "name": "Chinese Traditional",
    "nativeName": "繁體中文 (繁體)"
  }
};

export const languageNamespaces = ["app"];

export const defaultNamespace = languageNamespaces[0];

export const selectedLocale = getLocaleFromCookie(); // Replace with your cookie reading logic
