"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shadesOfPurple = void 0;
const createTheme_1 = require("../createTheme");
const dark_1 = require("./dark");
exports.shadesOfPurple = (0, createTheme_1.unstable_createTheme)({
    baseTheme: dark_1.dark,
    variables: {
        colorBackground: '#3f3c77',
        colorPrimary: '#f8d80d',
        colorTextOnPrimaryBackground: '#38375f',
        colorInputText: '#a1fdfe',
        colorShimmer: 'rgba(161,253,254,0.36)',
        shadowShimmer: '1px 1px 2px rgba(0,0,0,0.36)',
    },
});
