"use strict";
var _a, _IsomorphicClerk_loaded, _IsomorphicClerk_domain, _IsomorphicClerk_proxyUrl, _IsomorphicClerk_instance;
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const shared_1 = require("@clerk/shared");
const errors_1 = require("./errors");
const utils_1 = require("./utils");
class IsomorphicClerk {
    get loaded() {
        return tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f");
    }
    static getOrCreateInstance(options) {
        // During SSR: a new instance should be created for every request
        // During CSR: use the cached instance for the whole lifetime of the app
        // This method should be idempotent in both scenarios
        if (!(0, shared_1.inClientSide)() || !tslib_1.__classPrivateFieldGet(this, _a, "f", _IsomorphicClerk_instance)) {
            tslib_1.__classPrivateFieldSet(this, _a, new IsomorphicClerk(options), "f", _IsomorphicClerk_instance);
        }
        return tslib_1.__classPrivateFieldGet(this, _a, "f", _IsomorphicClerk_instance);
    }
    get domain() {
        // This getter can run in environments where window is not available.
        // In those cases we should expect and use domain as a string
        if (typeof window !== 'undefined' && window.location) {
            return (0, shared_1.handleValueOrFn)(tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_domain, "f"), new URL(window.location.href), '');
        }
        if (typeof tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_domain, "f") === 'function') {
            throw new Error(errors_1.unsupportedNonBrowserDomainOrProxyUrlFunction);
        }
        return tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_domain, "f") || '';
    }
    get proxyUrl() {
        // This getter can run in environments where window is not available.
        // In those cases we should expect and use proxy as a string
        if (typeof window !== 'undefined' && window.location) {
            return (0, shared_1.handleValueOrFn)(tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_proxyUrl, "f"), new URL(window.location.href), '');
        }
        if (typeof tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_proxyUrl, "f") === 'function') {
            throw new Error(errors_1.unsupportedNonBrowserDomainOrProxyUrlFunction);
        }
        return tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_proxyUrl, "f") || '';
    }
    constructor(options) {
        this.clerkjs = null;
        this.preopenSignIn = null;
        this.preopenSignUp = null;
        this.preopenUserProfile = null;
        this.preopenOrganizationProfile = null;
        this.premountSignInNodes = new Map();
        this.premountSignUpNodes = new Map();
        this.premountUserProfileNodes = new Map();
        this.premountUserButtonNodes = new Map();
        this.premountOrganizationProfileNodes = new Map();
        this.premountCreateOrganizationNodes = new Map();
        this.premountOrganizationSwitcherNodes = new Map();
        this.premountMethodCalls = new Map();
        this.loadedListeners = [];
        _IsomorphicClerk_loaded.set(this, false);
        _IsomorphicClerk_domain.set(this, void 0);
        _IsomorphicClerk_proxyUrl.set(this, void 0);
        this.addOnLoaded = (cb) => {
            this.loadedListeners.push(cb);
        };
        this.emitLoaded = () => {
            this.loadedListeners.forEach(cb => cb());
            this.loadedListeners = [];
        };
        this.hydrateClerkJS = (clerkjs) => {
            if (!clerkjs) {
                throw new Error('Failed to hydrate latest Clerk JS');
            }
            this.clerkjs = clerkjs;
            this.premountMethodCalls.forEach(cb => cb());
            if (this.preopenSignIn !== null) {
                clerkjs.openSignIn(this.preopenSignIn);
            }
            if (this.preopenSignUp !== null) {
                clerkjs.openSignUp(this.preopenSignUp);
            }
            if (this.preopenUserProfile !== null) {
                clerkjs.openUserProfile(this.preopenUserProfile);
            }
            this.premountSignInNodes.forEach((props, node) => {
                clerkjs.mountSignIn(node, props);
            });
            this.premountSignUpNodes.forEach((props, node) => {
                clerkjs.mountSignUp(node, props);
            });
            this.premountUserProfileNodes.forEach((props, node) => {
                clerkjs.mountUserProfile(node, props);
            });
            this.premountUserButtonNodes.forEach((props, node) => {
                clerkjs.mountUserButton(node, props);
            });
            tslib_1.__classPrivateFieldSet(this, _IsomorphicClerk_loaded, true, "f");
            this.emitLoaded();
            return this.clerkjs;
        };
        this.__unstable__updateProps = (props) => {
            // Handle case where accounts has clerk-react@4 installed, but clerk-js@3 is manually loaded
            if (this.clerkjs && '__unstable__updateProps' in this.clerkjs) {
                this.clerkjs.__unstable__updateProps(props);
            }
            else {
                return undefined;
            }
        };
        /**
         * `setActive` can be used to set the active session and/or organization.
         * It will eventually replace `setSession`.
         *
         * @experimental
         */
        this.setActive = ({ session, organization, beforeEmit }) => {
            if (this.clerkjs) {
                return this.clerkjs.setActive({ session, organization, beforeEmit });
            }
            else {
                return Promise.reject();
            }
        };
        this.setSession = (session, beforeEmit) => {
            return this.setActive({ session, beforeEmit });
        };
        this.openSignIn = (props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.openSignIn(props);
            }
            else {
                this.preopenSignIn = props;
            }
        };
        this.closeSignIn = () => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.closeSignIn();
            }
            else {
                this.preopenSignIn = null;
            }
        };
        this.openUserProfile = (props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.openUserProfile(props);
            }
            else {
                this.preopenUserProfile = props;
            }
        };
        this.closeUserProfile = () => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.closeUserProfile();
            }
            else {
                this.preopenUserProfile = null;
            }
        };
        this.openOrganizationProfile = (props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.openOrganizationProfile(props);
            }
            else {
                this.preopenOrganizationProfile = props;
            }
        };
        this.closeOrganizationProfile = () => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.closeOrganizationProfile();
            }
            else {
                this.preopenOrganizationProfile = null;
            }
        };
        this.openSignUp = (props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.openSignUp(props);
            }
            else {
                this.preopenSignUp = props;
            }
        };
        this.closeSignUp = () => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.closeSignUp();
            }
            else {
                this.preopenSignUp = null;
            }
        };
        this.mountSignIn = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountSignIn(node, props);
            }
            else {
                this.premountSignInNodes.set(node, props);
            }
        };
        this.unmountSignIn = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountSignIn(node);
            }
            else {
                this.premountSignInNodes.delete(node);
            }
        };
        this.mountSignUp = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountSignUp(node, props);
            }
            else {
                this.premountSignUpNodes.set(node, props);
            }
        };
        this.unmountSignUp = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountSignUp(node);
            }
            else {
                this.premountSignUpNodes.delete(node);
            }
        };
        this.mountUserProfile = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountUserProfile(node, props);
            }
            else {
                this.premountUserProfileNodes.set(node, props);
            }
        };
        this.unmountUserProfile = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountUserProfile(node);
            }
            else {
                this.premountUserProfileNodes.delete(node);
            }
        };
        this.mountOrganizationProfile = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountOrganizationProfile(node, props);
            }
            else {
                this.premountOrganizationProfileNodes.set(node, props);
            }
        };
        this.unmountOrganizationProfile = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountOrganizationProfile(node);
            }
            else {
                this.premountOrganizationProfileNodes.delete(node);
            }
        };
        this.mountCreateOrganization = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountCreateOrganization(node, props);
            }
            else {
                this.premountCreateOrganizationNodes.set(node, props);
            }
        };
        this.unmountCreateOrganization = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountCreateOrganization(node);
            }
            else {
                this.premountCreateOrganizationNodes.delete(node);
            }
        };
        this.mountOrganizationSwitcher = (node, props) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountOrganizationSwitcher(node, props);
            }
            else {
                this.premountOrganizationSwitcherNodes.set(node, props);
            }
        };
        this.unmountOrganizationSwitcher = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountOrganizationSwitcher(node);
            }
            else {
                this.premountOrganizationSwitcherNodes.delete(node);
            }
        };
        this.mountUserButton = (node, userButtonProps) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.mountUserButton(node, userButtonProps);
            }
            else {
                this.premountUserButtonNodes.set(node, userButtonProps);
            }
        };
        this.unmountUserButton = (node) => {
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                this.clerkjs.unmountUserButton(node);
            }
            else {
                this.premountUserButtonNodes.delete(node);
            }
        };
        this.addListener = (listener) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.addListener(listener); };
            if (this.clerkjs) {
                callback();
            }
            else {
                this.premountMethodCalls.set('addListener', callback);
            }
        };
        this.navigate = (to) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.navigate(to); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                void callback();
            }
            else {
                this.premountMethodCalls.set('navigate', callback);
            }
        };
        this.redirectWithAuth = (...args) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectWithAuth(...args); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                void callback();
            }
            else {
                this.premountMethodCalls.set('redirectWithAuth', callback);
            }
        };
        this.redirectToSignIn = (opts) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToSignIn(opts); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                void callback();
            }
            else {
                this.premountMethodCalls.set('redirectToSignIn', callback);
            }
        };
        this.redirectToSignUp = (opts) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToSignUp(opts); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                void callback();
            }
            else {
                this.premountMethodCalls.set('redirectToSignUp', callback);
            }
        };
        this.redirectToUserProfile = () => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToUserProfile(); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                callback();
            }
            else {
                this.premountMethodCalls.set('redirectToUserProfile', callback);
            }
        };
        this.redirectToHome = () => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToHome(); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                callback();
            }
            else {
                this.premountMethodCalls.set('redirectToHome', callback);
            }
        };
        this.redirectToOrganizationProfile = () => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToOrganizationProfile(); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                callback();
            }
            else {
                this.premountMethodCalls.set('redirectToOrganizationProfile', callback);
            }
        };
        this.redirectToCreateOrganization = () => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.redirectToCreateOrganization(); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                callback();
            }
            else {
                this.premountMethodCalls.set('redirectToCreateOrganization', callback);
            }
        };
        this.handleRedirectCallback = (params) => {
            var _b;
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.handleRedirectCallback(params); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                void ((_b = callback()) === null || _b === void 0 ? void 0 : _b.catch(() => {
                    // This error is caused when the host app is using React18
                    // and strictMode is enabled. This useEffects runs twice because
                    // the clerk-react ui components mounts, unmounts and mounts again
                    // so the clerk-js component loses its state because of the custom
                    // unmount callback we're using.
                    // This needs to be solved by tweaking the logic in uiComponents.tsx
                    // or by making handleRedirectCallback idempotent
                }));
            }
            else {
                this.premountMethodCalls.set('handleRedirectCallback', callback);
            }
        };
        this.handleMagicLinkVerification = async (params) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.handleMagicLinkVerification(params); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('handleMagicLinkVerification', callback);
            }
        };
        this.authenticateWithMetamask = async (params) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.authenticateWithMetamask(params); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('authenticateWithMetamask', callback);
            }
        };
        this.createOrganization = async (params) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.createOrganization(params); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('createOrganization', callback);
            }
        };
        this.getOrganizationMemberships = async () => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.getOrganizationMemberships(); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('getOrganizationMemberships', callback);
            }
        };
        this.getOrganization = async (organizationId) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.getOrganization(organizationId); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('getOrganization', callback);
            }
        };
        this.signOut = async (signOutCallbackOrOptions, options) => {
            const callback = () => { var _b; return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.signOut(signOutCallbackOrOptions, options); };
            if (this.clerkjs && tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
                return callback();
            }
            else {
                this.premountMethodCalls.set('signOut', callback);
            }
        };
        const { Clerk = null, frontendApi, publishableKey } = options || {};
        this.frontendApi = frontendApi;
        this.publishableKey = publishableKey;
        tslib_1.__classPrivateFieldSet(this, _IsomorphicClerk_proxyUrl, options === null || options === void 0 ? void 0 : options.proxyUrl, "f");
        tslib_1.__classPrivateFieldSet(this, _IsomorphicClerk_domain, options === null || options === void 0 ? void 0 : options.domain, "f");
        this.options = options;
        this.Clerk = Clerk;
        this.mode = (0, shared_1.inClientSide)() ? 'browser' : 'server';
        void this.loadClerkJS();
    }
    async loadClerkJS() {
        var _b, _c;
        if (this.mode !== 'browser' || tslib_1.__classPrivateFieldGet(this, _IsomorphicClerk_loaded, "f")) {
            return;
        }
        // Store frontendAPI value on window as a fallback. This value can be used as a
        // fallback during ClerkJS hot loading in case ClerkJS fails to find the
        // "data-clerk-frontend-api" attribute on its script tag.
        // This can happen when the DOM is altered completely during client rehydration.
        // For example, in Remix with React 18 the document changes completely via `hydrateRoot(document)`.
        // For more information refer to:
        // - https://github.com/remix-run/remix/issues/2947
        // - https://github.com/facebook/react/issues/24430
        if (typeof window !== 'undefined') {
            window.__clerk_frontend_api = this.frontendApi;
            window.__clerk_publishable_key = this.publishableKey;
            window.__clerk_proxy_url = this.proxyUrl;
            window.__clerk_domain = this.domain;
        }
        try {
            if (this.Clerk) {
                // Set a fixed Clerk version
                let c;
                if ((0, utils_1.isConstructor)(this.Clerk)) {
                    // Construct a new Clerk object if a constructor is passed
                    c = new this.Clerk(this.publishableKey || this.frontendApi || '', {
                        proxyUrl: this.proxyUrl,
                        domain: this.domain,
                    });
                    await c.load(this.options);
                }
                else {
                    // Otherwise use the instantiated Clerk object
                    c = this.Clerk;
                    if (!c.isReady()) {
                        await c.load(this.options);
                    }
                }
                global.Clerk = c;
            }
            else {
                // Hot-load latest ClerkJS from Clerk CDN
                await (0, utils_1.loadScript)({
                    frontendApi: this.frontendApi,
                    publishableKey: this.publishableKey,
                    proxyUrl: this.proxyUrl,
                    domain: this.domain,
                    scriptUrl: this.options.clerkJSUrl,
                    scriptVariant: this.options.clerkJSVariant,
                });
                if (!global.Clerk) {
                    throw new Error('Failed to download latest ClerkJS. Contact support@clerk.com.');
                }
                await global.Clerk.load(this.options);
            }
            if (((_b = global.Clerk) === null || _b === void 0 ? void 0 : _b.loaded) || ((_c = global.Clerk) === null || _c === void 0 ? void 0 : _c.isReady())) {
                return this.hydrateClerkJS(global.Clerk);
            }
            return;
        }
        catch (err) {
            const error = err;
            // In Next.js we can throw a full screen error in development mode.
            // However, in production throwing an error results in an infinite loop.
            // More info at: https://github.com/vercel/next.js/issues/6973
            if (process.env.NODE_ENV === 'production') {
                console.error(error.stack || error.message || error);
            }
            else {
                throw err;
            }
            return;
        }
    }
    get version() {
        var _b;
        return (_b = this.clerkjs) === null || _b === void 0 ? void 0 : _b.version;
    }
    get client() {
        if (this.clerkjs) {
            return this.clerkjs.client;
            // TODO: add ssr condition
        }
        else {
            return undefined;
        }
    }
    get session() {
        if (this.clerkjs) {
            return this.clerkjs.session;
        }
        else {
            return undefined;
        }
    }
    get user() {
        if (this.clerkjs) {
            return this.clerkjs.user;
        }
        else {
            return undefined;
        }
    }
    get organization() {
        if (this.clerkjs) {
            return this.clerkjs.organization;
        }
        else {
            return undefined;
        }
    }
    get __unstable__environment() {
        if (this.clerkjs) {
            return this.clerkjs.__unstable__environment;
            // TODO: add ssr condition
        }
        else {
            return undefined;
        }
    }
    __unstable__setEnvironment(...args) {
        if (this.clerkjs && '__unstable__setEnvironment' in this.clerkjs) {
            this.clerkjs.__unstable__setEnvironment(args);
        }
        else {
            return undefined;
        }
    }
}
exports.default = IsomorphicClerk;
_a = IsomorphicClerk, _IsomorphicClerk_loaded = new WeakMap(), _IsomorphicClerk_domain = new WeakMap(), _IsomorphicClerk_proxyUrl = new WeakMap();
_IsomorphicClerk_instance = { value: void 0 };
