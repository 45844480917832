"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMagicLink = exports.MagicLinkErrorCode = exports.isMagicLinkError = void 0;
const tslib_1 = require("tslib");
tslib_1.__exportStar(require("./contexts"), exports);
tslib_1.__exportStar(require("./components"), exports);
tslib_1.__exportStar(require("./hooks"), exports);
var errors_1 = require("./errors");
Object.defineProperty(exports, "isMagicLinkError", { enumerable: true, get: function () { return errors_1.isMagicLinkError; } });
Object.defineProperty(exports, "MagicLinkErrorCode", { enumerable: true, get: function () { return errors_1.MagicLinkErrorCode; } });
var useMagicLink_1 = require("./hooks/useMagicLink");
Object.defineProperty(exports, "useMagicLink", { enumerable: true, get: function () { return useMagicLink_1.useMagicLink; } });
/**
 * Vite does not define `global` by default
 * One workaround is to use the `define` config prop
 * https://vitejs.dev/config/#define
 * We are solving this in the SDK level to reduce setup steps.
 */
if (typeof global === 'undefined' && typeof window !== 'undefined' && !window.global) {
    window.global = window;
}
