"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadScript = void 0;
const shared_1 = require("@clerk/shared");
const info_1 = require("../info");
const errorThrower_1 = require("./errorThrower");
const isDevOrStageUrl_1 = require("./isDevOrStageUrl");
const FAILED_TO_LOAD_ERROR = 'Clerk: Failed to load Clerk';
const MISSING_BODY_ERROR = 'Clerk: Missing <body> element.';
const UNSTABLE_RELEASE_TAGS = ['staging', 'next'];
const extractNonStableTag = (packageVersion) => {
    var _a;
    const tag = (_a = packageVersion.match(/-(.*)\./)) === null || _a === void 0 ? void 0 : _a[1];
    return tag && UNSTABLE_RELEASE_TAGS.includes(tag) ? tag : undefined;
};
const extractMajorVersion = (packageVersion) => {
    return packageVersion.split('.')[0];
};
const forceStagingReleaseForClerkFapi = (frontendApi) => {
    return (frontendApi.endsWith('.lclstage.dev') ||
        frontendApi.endsWith('.stgstage.dev') ||
        frontendApi.endsWith('.clerkstage.dev') ||
        frontendApi.endsWith('.accountsstage.dev'));
};
function getScriptSrc({ publishableKey, frontendApi, domain, scriptUrl, scriptVariant = '', proxyUrl, }) {
    var _a, _b;
    if (scriptUrl) {
        return scriptUrl;
    }
    let scriptHost = '';
    if (!!proxyUrl && (0, shared_1.isValidProxyUrl)(proxyUrl)) {
        scriptHost = (0, shared_1.proxyUrlToAbsoluteURL)(proxyUrl).replace(/http(s)?:\/\//, '');
    }
    else if (domain && !(0, isDevOrStageUrl_1.isDevOrStagingUrl)(((_a = (0, shared_1.parsePublishableKey)(publishableKey)) === null || _a === void 0 ? void 0 : _a.frontendApi) || frontendApi || '')) {
        scriptHost = (0, shared_1.addClerkPrefix)(domain);
    }
    else {
        scriptHost = ((_b = (0, shared_1.parsePublishableKey)(publishableKey)) === null || _b === void 0 ? void 0 : _b.frontendApi) || frontendApi || '';
    }
    if (!scriptHost) {
        errorThrower_1.errorThrower.throwMissingPublishableKeyError();
    }
    const variant = scriptVariant ? `${scriptVariant.replace(/\.+$/, '')}.` : '';
    const getUrlForTag = (target) => {
        return `https://${scriptHost}/npm/@clerk/clerk-js@${target}/dist/clerk.${variant}browser.js`;
    };
    const nonStableTag = extractNonStableTag(info_1.LIB_VERSION);
    if (forceStagingReleaseForClerkFapi(scriptHost)) {
        return nonStableTag ? getUrlForTag(nonStableTag) : getUrlForTag('staging');
    }
    if (nonStableTag) {
        return getUrlForTag(nonStableTag);
    }
    return getUrlForTag(extractMajorVersion(info_1.LIB_VERSION));
}
async function loadScript(params) {
    return new Promise((resolve, reject) => {
        const { frontendApi, publishableKey, proxyUrl, domain } = params;
        if (global.Clerk) {
            resolve(null);
        }
        const script = document.createElement('script');
        if (!publishableKey && !frontendApi) {
            errorThrower_1.errorThrower.throwMissingPublishableKeyError();
        }
        if (publishableKey) {
            script.setAttribute('data-clerk-publishable-key', publishableKey);
        }
        else if (frontendApi) {
            script.setAttribute('data-clerk-frontend-api', frontendApi);
        }
        if (proxyUrl) {
            script.setAttribute('data-clerk-proxy-url', proxyUrl);
        }
        if (domain) {
            script.setAttribute('data-clerk-domain', domain);
        }
        script.setAttribute('crossorigin', 'anonymous');
        script.async = true;
        if (!document.body) {
            reject(MISSING_BODY_ERROR);
        }
        script.addEventListener('load', () => resolve(script));
        script.addEventListener('error', () => reject(FAILED_TO_LOAD_ERROR));
        script.src = getScriptSrc(params);
        document.body.appendChild(script);
    });
}
exports.loadScript = loadScript;
