import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import { ErrorMessage } from "./components/ErrorMessage";
import { fetchClerkLanguage } from "./utils/localization";
import { selectedLocale } from "./utils/languageConstants";

console.log('selectedLocale', selectedLocale)

const container = document.getElementById("app");
const root = createRoot(container!);

const authLanguagePath = `https://services.getexpertgpt.com/locales/${selectedLocale}/clerk.js`;
console.log("authLanguagePath", authLanguagePath)

import(authLanguagePath)
  .then(authLanguageModule => {
    console.log("authLanguagePath", authLanguagePath)
    console.log("authLanguageModule", authLanguageModule)
    const authLanguage = authLanguageModule.default;

    if (!authLanguage) {
      console.error(`An error occurred acquiring ${selectedLocale} auth lib language file.`);
      root.render(<ErrorMessage />);
      return;
    }
    root.render(<App authLanguage={authLanguage} />);
  })
  .catch(error => {
    console.error(`Error loading auth lib language file: ${error}`);
    root.render(<ErrorMessage error={error} />);
  });

//
// async function initializeApp() {
//   const clerkLanguage = await fetchClerkLanguage(selectedLocale);
//
//   if (!clerkLanguage) {
//     console.log(`An error occured acquiring ${selectedLocale} clerk locale file.`);
//     root.render(<ErrorMessage />);
//     return;
//   }
//   root.render(<App clerkLanguage={clerkLanguage} />);
// }
//
// initializeApp().then(r => {
//   console.log(`App initialized for ${selectedLocale}`, r);
// });
