"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsupportedNonBrowserDomainOrProxyUrlFunction = exports.invalidStateError = exports.isMagicLinkError = exports.MagicLinkErrorCode = exports.multipleChildrenInButtonComponent = exports.hocChildrenNotAFunctionError = exports.multipleClerkProvidersError = exports.noGuaranteedUserError = exports.noGuaranteedLoadedError = exports.noClerkProviderError = exports.noFrontendApiError = void 0;
exports.noFrontendApiError = 'Clerk: You must add the frontendApi prop to your <ClerkProvider>';
exports.noClerkProviderError = 'Clerk: You must wrap your application in a <ClerkProvider> component.';
const noGuaranteedLoadedError = (hookName) => `Clerk: You're calling ${hookName} before there's a guarantee the client has been loaded. Call ${hookName} from a child of <SignedIn>, <SignedOut>, or <ClerkLoaded>, or use the withClerk() HOC.`;
exports.noGuaranteedLoadedError = noGuaranteedLoadedError;
const noGuaranteedUserError = (hookName) => `Clerk: You're calling ${hookName} before there's a guarantee there's an active user. Call ${hookName} from a child of <SignedIn> or use the withUser() HOC.`;
exports.noGuaranteedUserError = noGuaranteedUserError;
exports.multipleClerkProvidersError = "Clerk: You've added multiple <ClerkProvider> components in your React component tree. Wrap your components in a single <ClerkProvider>.";
exports.hocChildrenNotAFunctionError = 'Clerk: Child of WithClerk must be a function.';
const multipleChildrenInButtonComponent = (name) => `Clerk: You've passed multiple children components to <${name}/>. You can only pass a single child component or text.`;
exports.multipleChildrenInButtonComponent = multipleChildrenInButtonComponent;
exports.MagicLinkErrorCode = {
    Expired: 'expired',
    Failed: 'failed',
};
function isMagicLinkError(err) {
    return !!err && (err.code === exports.MagicLinkErrorCode.Expired || err.code === exports.MagicLinkErrorCode.Failed);
}
exports.isMagicLinkError = isMagicLinkError;
exports.invalidStateError = 'Invalid state. Feel free to submit a bug or reach out to support here: https://clerk.com/support';
exports.unsupportedNonBrowserDomainOrProxyUrlFunction = 'Unsupported usage of domain or proxyUrl. The usage of domain or proxyUrl as function is not supported in non-browser environments.';
