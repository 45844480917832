import { ActionIcon, Box, Button, Card, Container, Flex, MediaQuery, Select, SimpleGrid, Skeleton, Stack, Textarea, useMantineTheme } from "@mantine/core";
import { IconAdjustments } from '@tabler/icons-react';

import { notifications } from "@mantine/notifications";
import { useLiveQuery } from "dexie-react-hooks";
import { findLast } from "lodash";
import { nanoid } from "nanoid";
import { useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { MessageItem } from "../components/MessageItem";
import { db } from "../db";
import { useChatId } from "../hooks/useChatId";
import { writingCharacters, writingFormats, writingStyles, writingTones } from "../utils/promptConstants";
import { styleSettings } from "../utils/styleConstants";
import { createChatCompletion } from "../utils/openai";
import { IconPlus } from "@tabler/icons-react";
import { CreatePromptModal } from "../components/CreatePromptModal";

export function ChatRoute() {

  const theme = useMantineTheme();
  const chatId = useChatId();
  const apiKey = useLiveQuery(async () => {
    return (await db.settings.where({ id: "general" }).first())?.openAiApiKey;
  });
  const messages = useLiveQuery(() => {
    if (!chatId) return [];
    return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
  }, [chatId]);
  const [content, setContent] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const chat = useLiveQuery(async () => {
    if (!chatId) return null;
    return db.chats.get(chatId);
  }, [chatId]);

  const [writingCharacter, setWritingCharacter] = useState<string | null>(null);
  const [writingTone, setWritingTone] = useState<string | null>(null);
  const [writingStyle, setWritingStyle] = useState<string | null>(null);
  const [writingFormat, setWritingFormat] = useState<string | null>(null);

  const getSystemMessage = () => {
    const message: string[] = [];
    if (writingCharacter) message.push(`You are ${writingCharacter}.`);
    if (writingTone) message.push(`Respond in ${writingTone} tone.`);
    if (writingStyle) message.push(`Respond in ${writingStyle} style.`);
    if (writingFormat) message.push(writingFormat);
    if (message.length === 0)
      message.push(
        "You are expertGPT a large language model trained to provide expert advice."
      );
    return message.join(" ");
  };

  const submit = async () => {
    if (submitting) return;

    if (!chatId) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "chatId is not defined. Please create a chat to get started.",
      });
      return;
    }

    if (!apiKey) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "OpenAI API Key is not defined. Please set your API Key",
      });
      return;
    }

    try {
      setSubmitting(true);

      await db.messages.add({
        id: nanoid(),
        chatId,
        content,
        role: "user",
        createdAt: new Date(),
      });
      setContent("");

      const result = await createChatCompletion(apiKey, [
        {
          role: "system",
          content: getSystemMessage(),
        },
        ...(messages ?? []).map((message) => ({
          role: message.role,
          content: message.content,
        })),
        { role: "user", content },
      ]);

      const assistantMessage = result.data.choices[0].message?.content;
      if (result.data.usage) {
        await db.chats.where({ id: chatId }).modify((chat) => {
          if (chat.totalTokens) {
            chat.totalTokens += result.data.usage!.total_tokens;
          } else {
            chat.totalTokens = result.data.usage!.total_tokens;
          }
        });
      }
      setSubmitting(false);

      await db.messages.add({
        id: nanoid(),
        chatId,
        content: assistantMessage ?? "unknown reponse",
        role: "assistant",
        createdAt: new Date(),
      });

      if (chat?.description === "New Chat") {
        const messages = await db.messages
          .where({ chatId })
          .sortBy("createdAt");
        const createChatDescription = await createChatCompletion(apiKey, [
          {
            role: "system",
            content: getSystemMessage(),
          },
          ...(messages ?? []).map((message) => ({
            role: message.role,
            content: message.content,
          })),
          {
            role: "user",
            content:
              "What would be a short and relevant title for this chat ? You must strictly answer with only the title, no other text is allowed.",
          },
        ]);
        const chatDescription =
          createChatDescription.data.choices[0].message?.content;

        if (createChatDescription.data.usage) {
          await db.chats.where({ id: chatId }).modify((chat) => {
            chat.description = chatDescription ?? "New Chat";
            if (chat.totalTokens) {
              chat.totalTokens +=
                createChatDescription.data.usage!.total_tokens;
            } else {
              chat.totalTokens = createChatDescription.data.usage!.total_tokens;
            }
          });
        }
      }
    } catch (error: any) {
      if (error.toJSON().message === "Network Error") {
        notifications.show({
          title: "Error",
          color: "red",
          message: "No internet connection.",
        });
      }
      const message = error.response?.data?.error?.message;
      if (message) {
        notifications.show({
          title: "Error",
          color: "red",
          message,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (!chatId) return null;

  const selectInput = {
    marginTop: styleSettings.chatRoute.modifierPulldown.marginTop,
    marginBottom: styleSettings.chatRoute.modifierPulldown.marginBottom,
    // @ts-ignore
    color: styleSettings[theme.colorScheme].selectColor,
    // @ts-ignore
    backgroundColor: styleSettings[theme.colorScheme].selectBackgroundColor,
  };

  return (
    <>
      <Container
        pt={styleSettings.chatRoute.messagesContainer.paddingTop}
        pb={styleSettings.chatRoute.messagesContainer.paddingBottom}
        className={styleSettings.chatRoute.messagesContainer.className}
      >
        <Stack
          spacing={styleSettings.chatRoute.messagesContainer.stackSpacing}
        >
          {messages?.map((message) => (
            <MessageItem key={message.id} message={message} />
          ))}
        </Stack>
        {submitting && (
          <Card
            withBorder
            mt={styleSettings.chatRoute.messageCard.marginTop}
          >
            <Skeleton
              height={styleSettings.chatRoute.messageCard.skeleton.height}
              radius={styleSettings.chatRoute.messageCard.skeleton.radius}
            />
            <Skeleton
              height={styleSettings.chatRoute.messageCard.skeleton.height}
              mt={styleSettings.chatRoute.messageCard.skeleton.marginTop}
              radius={styleSettings.chatRoute.messageCard.skeleton.radius}
            />
            <Skeleton
              height={styleSettings.chatRoute.messageCard.skeleton.height}
              mt={styleSettings.chatRoute.messageCard.skeleton.marginTop}
              radius={styleSettings.chatRoute.messageCard.skeleton.radius}
            />
            <Skeleton
              height={styleSettings.chatRoute.messageCard.skeleton.height}
              mt={styleSettings.chatRoute.messageCard.skeleton.marginTop}
              radius={styleSettings.chatRoute.messageCard.skeleton.radius}
            />
            <Skeleton
              height={styleSettings.chatRoute.messageCard.skeleton.height}
              mt={styleSettings.chatRoute.messageCard.skeleton.marginTop}
              radius={styleSettings.chatRoute.messageCard.skeleton.radius}
              width={styleSettings.chatRoute.messageCard.skeleton.width}
            />
          </Card>
        )}
      </Container>
      <Box
        py={styleSettings.chatRoute.promptSection.paddingY}
        // @ts-ignore
        sx={(theme) => ({
          position: styleSettings.chatRoute.promptSection.sxProp.position,
          bottom: styleSettings.chatRoute.promptSection.sxProp.bottom,
          left: styleSettings.chatRoute.promptSection.sxProp.left,
          right: styleSettings.chatRoute.promptSection.sxProp.right,
          [`@media (min-width: ${theme.breakpoints.md})`]: {
            left: styleSettings.chatRoute.promptSection.sxProp.mediaDeclarations.left,
          },
          // @ts-ignore
          backgroundColor: styleSettings[theme.colorScheme].chatBg,
        })}
      >
        <Container
          className={styleSettings.chatRoute.promptSection.promptContainer.className[theme.colorScheme]}
        >

          <Flex
            gap={styleSettings.chatRoute.promptSection.promptContainer.gap}
          >
            <Textarea
              key={chatId}
              styles={{
                input: {
                  // @ts-ignore
                  color: styleSettings[theme.colorScheme].inputColor,
                  // @ts-ignore
                  background: styleSettings[theme.colorScheme].inputBg,
                },
              }}
              placeholder="Your promptsadsfads here..."
              autosize
              autoFocus
              disabled={submitting}
              minRows={styleSettings.chatRoute.promptSection.minRows}
              maxRows={styleSettings.chatRoute.promptSection.maxRows}
              value={content}
              onChange={(event) => setContent(event.currentTarget.value)}
              onKeyDown={async (event) => {
                if (event.code === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  await submit();
                }
                if (event.code === "ArrowUp") {
                  const { selectionStart, selectionEnd } = event.currentTarget;
                  if (selectionStart !== selectionEnd) return;
                  if (selectionStart !== 0) return;
                  event.preventDefault();
                  const nextUserMessage = findLast(
                    messages,
                    (message) => message.role === "user"
                  );
                  setContent(nextUserMessage?.content ?? "");
                }
                if (event.code === "ArrowDown") {
                  const { selectionStart, selectionEnd } = event.currentTarget;
                  if (selectionStart !== selectionEnd) return;
                  if (selectionStart !== event.currentTarget.value.length)
                    return;
                  event.preventDefault();
                  const lastUserMessage = findLast(
                    messages,
                    (message) => message.role === "user"
                  );
                  if (lastUserMessage?.content === content) {
                    setContent("");
                  }
                }
              }}
              rightSection={

                <ActionIcon>
                  <IconAdjustments size="1.125rem" />
                </ActionIcon>
                
              }
            />
          </Flex>

          {/*<MediaQuery largerThan="sm" styles={{ display: "none" }}>*/}
          {/*  */}
          {/*</MediaQuery>*/}
          <SimpleGrid
            mb="sm"
            spacing="xs"
            className={`prompt_modifier_select_menus_${theme.colorScheme}`}
            styles={{
              marginBottom: 0,
            }}
            breakpoints={[
              { minWidth: "sm", cols: 3 },
              { maxWidth: "sm", cols: 3 },
            ]}
          >
            <Select
              value={writingTone}
              onChange={setWritingTone}
              data={writingTones}
              placeholder="Tone"
              variant="filled"
              searchable
              clearable
              sx={{ flex: 1 }}
              inputWrapperOrder={["label", "input", "error", "description"]}
              styles={{
                input: selectInput,
              }}
            />
            <Select
              value={writingStyle}
              onChange={setWritingStyle}
              data={writingStyles}
              placeholder="Style"
              variant="filled"
              searchable
              clearable
              sx={{ flex: 1 }}
              styles={{
                input: selectInput,
              }}
            />
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
